// device-item.js
import React from 'react';

const DeviceItem = ({ device, assignPatient, deleteDevice, openEditModal }) => {
  const assignedPatientName = device.patient
    ? `${device.patient.firstName} ${device.patient.lastName}`
    : 'Unassigned';

  const hubSerialNumber = device.hub ? device.hub.serialNumber : 'N/A';

  return (
    <tr>
      <td>{device.serialNumber}</td>
      <td>{device.internalId}</td>
      <td>{hubSerialNumber}</td>
      <td>{assignedPatientName}</td>
      <td>
        <button className='btn btn-sm btn-primary mr-2' onClick={() => assignPatient(device)}>
          Assign Patient
        </button>
        <button className='btn btn-sm btn-secondary mr-2' onClick={() => openEditModal(device)}>
          Edit
        </button>
        <button
          className='btn btn-sm btn-danger'
          onClick={() => deleteDevice(device.id)}
        >
          Delete
        </button>
      </td>
    </tr>
  );
};

export default DeviceItem;
