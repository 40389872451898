import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import SmartWatch from '../assets/smartwatchblue.png'
import SmartWatchWhite from '../assets/smartwatchwhite.png'

import psIcon from '../assets/ps-icon.png'
import psLogo from '../assets/added-logo.png'

class Sidebar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isNewWindow: false,
      secondWindow: null,
      isCollapsed: true,
      isDevicesClicked: false,
    }
  }

  toggleCollapse(e) {
    this.setState({
      isCollapsed: !this.state.isCollapsed,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ isDevicesClicked: false })
    }
  }

  render() {
    const { location } = this.props
    const loggedIn = this.props.user.id
    const hide = { display: 'none' }
    const show = { display: 'block' }
    const arrow = this.state.isCollapsed
      ? 'fa-chevron-double-right'
      : 'fa-chevron-double-left'

    const isDevicesActive =
      location.pathname === '/devices' || this.state.isDevicesClicked
    const smartwatchIcon = isDevicesActive ? SmartWatchWhite : SmartWatch

    return (
      <div
        className='nav flex-md-column nav-pills mt-2'
        aria-orientation='vertical'
        style={{ height: '100%' }}
      >
        <div className='row d-flex align-items-center'>
          <a href='https://www.marketing.patientsafetyinc.com/'>
            <img
              className='ml-4 mb-4'
              src={psIcon}
              width='38'
              height='38'
              alt='PatientSafetyLogo'
            />
          </a>
          <a href='https://www.marketing.patientsafetyinc.com/'>
            <img
              className='mb-4'
              hidden={this.state.isCollapsed}
              src={psLogo}
              width='73.35'
              height='38'
              alt='PatientSafetyLogo'
            />
          </a>
          <i
            className={`col mr-2 mb-3 text-right btn btn-small fas ${arrow}`}
            onClick={this.toggleCollapse.bind(this)}
          ></i>
        </div>

        <NavLink
          className='nav-link'
          to='/patients'
          activeClassName='active'
          style={loggedIn ? show : hide}
        >
          <i className='mr-3 fad fa-bed' style={{ width: '18px' }}></i>
          <span hidden={this.state.isCollapsed}>Patients</span>
        </NavLink>
        <NavLink
          className='nav-link'
          to='/reports'
          activeClassName='active'
          style={loggedIn ? show : hide}
        >
          <i className='mr-3 far fa-file-chart-line' style={{ width: '18px' }}></i>
          <span hidden={this.state.isCollapsed}>Reports</span>
        </NavLink>
        <NavLink
          className='nav-link'
          to='/login'
          activeClassName='active'
          style={loggedIn ? hide : show}
        >
          <FontAwesomeIcon className='fa-fw mr-3' icon={faSignInAlt} />
          <span hidden={this.state.isCollapsed}>Login</span>
        </NavLink>
        <NavLink
          className='nav-link'
          to='/devices'
          activeClassName='active'
          style={loggedIn ? show : hide}
          onMouseUp={() => this.setState({ isDevicesClicked: true })}
        >
          <img
            src={smartwatchIcon}
            alt='Devices'
            style={{ width: '18px', marginRight: '12px', verticalAlign: 'middle' }}
          />
          <span hidden={this.state.isCollapsed}>Devices</span>
        </NavLink>
      </div>
    )
  }
}

export default withRouter(Sidebar)
