// deviceFormModal.js
import React, { Component } from 'react';
import axios from 'axios';

class DeviceFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHub: false,
      serialNumber: '',
      internalId: '',
      errorMessage: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggleIsHub = this.toggleIsHub.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  toggleIsHub() {
    this.setState((prevState) => ({ isHub: !prevState.isHub }));
  }

  handleSubmit(event) {
    event.preventDefault();
    const { serialNumber, internalId, isHub } = this.state;
    const { organizationId } = this.props;

    if (!serialNumber || !internalId) {
      this.setState({ errorMessage: 'Please fill out all required fields.' });
      return;
    }

    const data = {
      serialNumber,
      internalId,
      organizationId,
    };

    const url = isHub ? '/hubs' : '/devices';

    axios
      .post(url, data)
      .then((response) => {
        this.props.onDeviceCreated();
      })
      .catch((error) => {
        console.error('Error creating device:', error);
        this.setState({ errorMessage: 'Failed to create device.' });
      });
  }

  render() {
    const { isHub, serialNumber, internalId, errorMessage } = this.state;
    const { closeModal } = this.props;

    return (
      <>
        {/* Modal Backdrop */}
        <div className="modal-backdrop show"></div>

        {/* Modal */}
        <div
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          style={{ display: 'block' }}
          aria-modal="true"
          onClick={closeModal}
        >
          <div
            className="modal-dialog modal-md modal-dialog-centered"
            role="document"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{isHub ? 'Add Hub' : 'Add Device'}</h5>
                <button type="button" className="close" onClick={closeModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="modal-body">
                  {errorMessage && (
                    <div className="alert alert-danger" role="alert">
                      {errorMessage}
                    </div>
                  )}
                  <div className="form-group form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="isHub"
                      checked={isHub}
                      onChange={this.toggleIsHub}
                    />
                    <label className="form-check-label" htmlFor="isHub">
                      Is this a Hub?
                    </label>
                  </div>
                  <div className="form-group">
                    <label htmlFor="serialNumber">Serial Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="serialNumber"
                      name="serialNumber"
                      value={serialNumber}
                      onChange={this.handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="internalId">Internal ID</label>
                    <input
                      type="text"
                      className="form-control"
                      id="internalId"
                      name="internalId"
                      value={internalId}
                      onChange={this.handleInputChange}
                      required
                    />
                  </div>
                  {/* Additional fields can be added here */}
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    {isHub ? 'Create Hub' : 'Create Device'}
                  </button>
                  <button type="button" className="btn btn-secondary" onClick={closeModal}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DeviceFormModal;
