// Devices.js
import React, { Component } from 'react';
import $ from 'jquery';
import DeviceItem from './device-item';
import DeviceSelectModal from './deviceSelectModal';
import DeviceFormModal from './deviceFormModal'; // Assuming this is the modal we used earlier
import EditDeviceModal from './editDeviceModal'; // Import the new edit modal component

class Devices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      devices: [],
      showDeviceSelectModal: false,
      showDeviceFormModal: false,
      showEditDeviceModal: false, // New state for edit modal visibility
      selectedDevice: null,
    };
    this.getDevices = this.getDevices.bind(this);
    this.handleDevices = this.handleDevices.bind(this);
    this.assignPatient = this.assignPatient.bind(this);
    this.deleteDevice = this.deleteDevice.bind(this);
    this.openDeviceFormModal = this.openDeviceFormModal.bind(this);
    this.closeDeviceFormModal = this.closeDeviceFormModal.bind(this);
    this.handleDeviceCreated = this.handleDeviceCreated.bind(this);
    this.openEditModal = this.openEditModal.bind(this); // Method to open edit modal
    this.closeEditModal = this.closeEditModal.bind(this);
    this.handleDeviceUpdated = this.handleDeviceUpdated.bind(this);
    this.closeDeviceSelectModal = this.closeDeviceSelectModal.bind(this);
  }

  componentDidMount() {
    this.getDevices();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.organization.id !== this.props.organization.id) {
      this.getDevices();
    }
  }

  getDevices() {
    const url = '/devices?organizationId=' + this.props.organization.id;
    $.get(url)
      .done(this.handleDevices)
      .fail((error) => {
        console.log(error);
      });
  }
  handleDevices(data) {
    console.log(data);
    this.setState({
      devices: data.rows || [],
    });
  }
  assignPatient(device) {
    this.setState({
      selectedDevice: device,
      showDeviceSelectModal: true,
    });
  }

  deleteDevice(deviceId) {
    if (window.confirm('Are you sure you want to delete this device?')) {
      $.ajax({
        url: `/devices/${deviceId}`,
        method: 'DELETE',
      })
        .done(() => {
          this.getDevices();
        })
        .fail((error) => {
          console.error('Error deleting device:', error);
          alert('Failed to delete device.');
        });
    }
  }
  openDeviceFormModal() {
    this.setState({ showDeviceFormModal: true });
  }

  closeDeviceFormModal() {
    this.setState({ showDeviceFormModal: false });
  }

  handleDeviceCreated() {
    this.closeDeviceFormModal();
    this.getDevices();
  }

  closeDeviceSelectModal() {
    this.setState({
      showDeviceSelectModal: false,
      selectedDevice: null,
    });
  }

  handleDeviceAssignment() {
    this.getDevices();
  }

  handleDeviceSelection(patient) {
    const { selectedDevice } = this.state;
    const url = `/devices/${selectedDevice.id}`;
    const data = {
      patientId: patient.id,
    };
    $.ajax({
      url: url,
      method: 'PUT',
      data: data,
    })
      .done(() => {
        this.getDevices();
        this.closeDeviceSelectModal();
      })
      .fail((error) => {
        console.log(error);
        alert('Error assigning patient');
        this.closeDeviceSelectModal();
      });
  }

  openEditModal(device) {
    this.setState({ showEditDeviceModal: true, selectedDevice: device });
  }

  closeEditModal() {
    this.setState({ showEditDeviceModal: false, selectedDevice: null });
  }

  handleDeviceUpdated() {
    this.closeEditModal();
    this.getDevices();
  }

  renderDeviceItem(device, index) {
    return (
      <DeviceItem
        key={device.id}
        device={device}
        assignPatient={this.assignPatient}
        deleteDevice={this.deleteDevice}
        openEditModal={this.openEditModal} // Pass the method to DeviceItem
      />
    );
  }

  render() {
    const deviceItems = this.state.devices.map(this.renderDeviceItem.bind(this));
    return (
      <div>
        <div className='text-center'>
          Purchase the latest oximeters, accessories, and disposable probes at our online store:
          <a href='https://www.sleepsat.com/online-store'> www.sleepsat.com/online-store</a>
        </div>
        <div className='card mb-4'>
          <div className='card-header d-flex justify-content-between align-items-center'>
            <span className='h4'>Devices</span>
            <button className='btn btn-primary' onClick={this.openDeviceFormModal}>
              + Add Device
            </button>
          </div>
          <div className='card-body'>
            <div className='table-responsive'>
              <table className='table table-striped'>
                <thead>
                  <tr>
                    <th>Serial Number</th>
                    <th>Internal ID</th>
                    <th>Hub Serial Number</th>
                    <th>Assigned Patient</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{deviceItems}</tbody>
              </table>
            </div>
          </div>
          {this.state.showDeviceSelectModal && (
            <DeviceSelectModal
              device={this.state.selectedDevice}
              closeModal={this.closeDeviceSelectModal}
              onPatientSelect={this.handleDeviceSelection}
              organization={this.props.organization}
              user={this.props.user}
              onDeviceAssigned={this.handleDeviceAssignment}
            />
          )}
          {this.state.showDeviceFormModal && (
            <DeviceFormModal
              closeModal={this.closeDeviceFormModal}
              organizationId={this.props.organization.id}
              onDeviceCreated={this.handleDeviceCreated}
            />
          )}
          {this.state.showEditDeviceModal && (
            <EditDeviceModal
              closeModal={this.closeEditModal}
              device={this.state.selectedDevice}
              organizationId={this.props.organization.id}
              onDeviceUpdated={this.handleDeviceUpdated}
              organizations={this.props.organizations} // Pass organizations if needed
            />
          )}
        </div>
      </div>
    );
  }
}

export default Devices;
