import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import $ from 'jquery';
import Alert from './alert';
import Search from './patient-search';
import Paginator from './paginator';

class PatientsSelect extends Component {
	constructor(props) {
		super(props);
		this.url = '/patients';
		this.state = {
			patients: [],
			searched: [],
			limit: 10,
			offset: 0,
			limitedPatients: [],
			fetchingPatients: false,
			alertMessage: null,
			alertType: 'alert-success',
			showConfirmationDialog: false,
			selectedPatient: null,
			showCloseButton: true,
		};

		this.setFilteredPatients = this.setFilteredPatients.bind(this);
		this.dataHandler = this.dataHandler.bind(this);
		this.handelPatientSelection = this.handelPatientSelection.bind(this);
		this.renderListItem = this.renderListItem.bind(this);
		this.removeAlert = this.removeAlert.bind(this);
		//this.mergePatients = this.mergePatients.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.searchHandler = this.searchHandler.bind(this);
		this.clearOffSet = this.clearOffSet.bind(this);
		this.read = this.read.bind(this);
		this.confirmMerge = this.confirmMerge.bind(this);
		this.cancelMerge = this.cancelMerge.bind(this);
	}

	componentDidMount() {
		this.read();
	}

	componentDidUpdate(prevProps, prevState) {
		const userUpdated = this.props.user.id !== prevProps.user.id;
		const organizationUpdated = this.props.organization.id !== prevProps.organization.id;
		const offsetChanged = this.state.offset !== prevState.offset;
	
		if ((userUpdated || organizationUpdated) && offsetChanged) {
			this.setState({
				patients: [],
			}, this.read);
		}
	}
	

	searchHandler(event) {
		event.preventDefault();
		const searchTermFirst = event.target.elements.firstName.value.toLowerCase();
		const searchTermLast = event.target.elements.lastName.value.toLowerCase();
		const filtered = this.state.patients.filter(
			(e) =>
				e.firstName.toLowerCase().includes(searchTermFirst) &&
				e.lastName.toLowerCase().includes(searchTermLast)
		);

		const filteredOut = Array.from(new Set(filtered.map((e) => e.id))).map((id) =>
			filtered.find((e) => e.id === id)
		);

		if (filteredOut.length < 1) {
			this.setState(
				(prevState) => ({
					...prevState,
					searched: [],
					searchMessage: 'No Results Found',
				}),
				() => {
					// clear the message after 3 seconds
					setTimeout(() => {
						this.setState({ searchMessage: '' });
					}, 3000);
				}
			);
		} else {
			this.setState((prevState) => ({
				...prevState,
				searched: filteredOut,
				searchMessage: '',
			}));
		}
	}

	read(params = {}) {
		const success = this.dataHandler;
		const options = {
			organizationId: this.props.organization.id,
			limit: 100,
			offset: this.state.offset,
		};
		for (const key in params) {
			const value = params[key];
			if (value) options[key] = params[key];
		}

		$.ajax({
			method: 'GET',
			url: this.url,
			data: options,
		}).done(success);
	}

	dataHandler(data) {
		const newPatients = [...this.state.patients, ...data.rows];
		const hasMore = data.count > newPatients.length;

		this.setState(
			{
				patients: newPatients,
				offset: this.state.offset + 100,
				fetchingPatients: hasMore,
			},
			() => {
				if (hasMore) {
					this.read();
				} else {
					this.setState({
						offset: 0,
					});
				}
			}
		);
	}

	removeAlert() {
		this.setState({
			alertMessage: null,
		});
	}

	setFilteredPatients(data) {
		console.log('Paginator.setFilteredPatients called:' + data);
		const currentLimitedPatients = this.state.limitedPatients;
		const isDataChanged = JSON.stringify(currentLimitedPatients) !== JSON.stringify(data);
	
		if (isDataChanged) {
			this.setState({
				limitedPatients: data,
			});
		}
	}
	

	clearOffSet() {
		this.setState({
			offset: 0,
		});
	}

	handelPatientSelection(secondPatientData) {
		this.setState({
		  selectedPatient: secondPatientData,
		  showConfirmationDialog: true,
		  showCloseButton: false,
		});
	  }

	  confirmMerge() {
		const mainPatientData = this.props.mainPatientData;
		const secondPatientData = this.state.selectedPatient;
	  
		const failure = (res) => {
		  const body = res.responseJSON;
		  if (res.status !== 200) {
			this.setState({
			  alertMessage: res.statusText,
			  alertType: 'alert-danger',
			});
		  } else {
			const error = new Error(body.error.message);
			this.setState({
			  alertMessage: error.message,
			  alertType: 'alert-danger',
			});
		  }
		};
	  
		const data = {
		  mainPatientId: mainPatientData.id,
		  secondaryPatientId: secondPatientData.id,
		};
	  
		$.ajax({
		  method: 'PUT',
		  url: `/patients/${mainPatientData.id}/merge`,
		  data: data,
		})
		  .done((response) => {
			this.setState({
			  alertMessage: 'Patients merged successfully!',
			  alertType: 'alert-success',
			  showConfirmationDialog: false,
			  selectedPatient: null,
			});
			if (this.props.closeModal) {
			  this.props.closeModal();
			}
		  })
		  .fail(failure);
	  }
	  
	  cancelMerge() {
		this.setState({
		  showConfirmationDialog: false,
		  selectedPatient: null,
		});
	  }
	  

	renderListItem(data) {
		const id = data.id;
		const name = `${data.firstName} ${data.lastName}`;
		const birthDate = moment(data.birthDate).utc().format('MMMM D, YYYY');
		return (
			<li
				key={id}
				className='list-group-item list-group-item-action'
				onClick={() => this.handelPatientSelection(data)}
			>
				<div className='ml-4 row'>
					<span>{name}</span>
				</div>
				<div className='ml-4 row'>
					<small>{birthDate}</small>
				</div>
			</li>
		);
	}

	closeModal() {
		if (this.props.closeModal) {
			this.props.closeModal();
		}
	}

	render() {
		const mainPatientId = this.props.mainPatientData.id;
		const filteredPatients = this.state.patients.filter((e) => e.id !== mainPatientId);

		const patients = this.state.limitedPatients.map(this.renderListItem);
		const conditionalData = this.state.searched.length > 0
			? this.state.searched.filter(patient => patient.id !== mainPatientId)
			: this.state.patients.filter(patient => patient.id !== mainPatientId);

		return (
			<div className='card'>
				<div className='card-header'>
					<div className='row align-items-center'>
						<div className='col'>
							<span className='h4'>Select Patient to Merge with</span>
						</div>
					</div>
				</div>
				<div className='card-body'>
					<div>
						<div className='row'>
							<div className='col'>
								<Search onSubmit={this.searchHandler} />
							</div>
						</div>
						<div className='row'>
							<div className='col'>
								<ul className='list-group'>{patients}</ul>
							</div>
						</div>
					</div>
					<div className='col mt-2'>
						{conditionalData.length > 0 && (
							<nav>
								<Paginator
									data={conditionalData}
									limit={this.state.limit}
									render={this.setFilteredPatients}
								/>
							</nav>
						)}
					</div>
					<div className='row mt-3'>
						<div className='col'>

						</div>
						{this.state.showCloseButton && (
						<div className='col text-right'>
							<button className='btn btn-secondary' onClick={this.closeModal}>
								Close
							</button>
						</div>
						)}
					</div>
				</div>
				<Alert
					message={this.state.alertMessage}
					type={this.state.alertType}
					onExit={this.removeAlert}
				/>
				      {/* Confirmation Dialog */}
				{this.state.showConfirmationDialog && (
					<div className="confirmation-overlay">
					<div className="confirmation-dialog">
						<div className="modal-header">
						</div>
						<div className="modal-body">
						<p>
							Are you sure you want to combine these patients, which will
							result in the removal of:
							<strong>
							{' '}
							{this.state.selectedPatient.firstName}{' '}
							{this.state.selectedPatient.lastName}
							</strong>
							?
						</p>
						</div>
						<div className="modal-footer">
						<button
							className="btn btn-danger"
							onClick={this.confirmMerge}
						>
							Yes, Merge
						</button>
						<button
							className="btn btn-secondary"
							onClick={this.cancelMerge}
						>
							Cancel
						</button>
						</div>
					</div>
					</div>
				)}
			</div>
		);
	}
}


export default PatientsSelect;
