import React, {Component} from 'react'
import SubscriptionItem from './subscription-item'
import PlanItem from './plan-item'
import $ from 'jquery'
import Payments from './payment'
import DeviceItem from './device-item'
import DeviceSelectModal from './deviceSelectModal'

class Subscriptions extends Component {

	constructor(props){
		super(props)
		this.state = {
			plans: [],
			subscriptions: [],
			cards: [],
			devices: [],
			showDeviceSelectModal: false,
			selectedDevice: null,
		}
		this.getDevices = this.getDevices.bind(this);
		this.handleDevices = this.handleDevices.bind(this);
		this.assignPatient = this.assignPatient.bind(this);
		this.closeDeviceSelectModal = this.closeDeviceSelectModal.bind(this);
		this.handleDeviceSelection = this.handleDeviceSelection.bind(this);
		this.handleDeviceAssignment = this.handleDeviceAssignment.bind(this);

	}

	componentDidMount(){
		this.getSubscriptions()
		this.getPlans()
		this.getCards()
		this.getDevices()
	}

	componentDidUpdate(prevProps,prevState){
		if (prevProps.organization.id !== this.props.organization.id){
			this.getSubscriptions()
			this.getDevices()
			
		}
	}

	getPlans(){
		var success = this.handlePlans.bind(this)
		$.get('/plans').done(success)
	}

	handlePlans(data){
		this.setState({
			plans: data.data
		})
	}

	getSubscriptions(){
		var id = this.props.organization.id
		var url = '/organizations/' + id + '/subscriptions'
		var success = this.handleSubscriptions.bind(this)
		$.get(url).done(success)
	}

	handleSubscriptions(data){
		console.log(data)
		this.setState({
			subscriptions: data.subscriptions.data,
			customer: data.customer
		})
	}

	handleSubscriptionError(data){
		this.setState({
			subscriptions: []
		})
	}

	getCards(){
		var url = '/organizations/' + this.props.organization.id +'/cards'
		var success = this.handleCards.bind(this)
		$.ajax({
			url: url
		}).done(success)
	}

	handleCards(data){
		this.setState({
			cards: data.data
		})
	}

	getDevices(){
		const url = '/devices?organizationId=' + this.props.organization.id
		$.get(url)
		.done(this.handleDevices)
		.fail((error) => {
			console.log(error)
		})
	}

	handleDevices(data){
		this.setState({
			devices: data.rows || []
		})
	}

	assignPatient(device){
		this.setState({
			selectedDevice: device,
			showDeviceSelectModal: true
		})
	}

	closeDeviceSelectModal(){
		this.setState({
			showDeviceSelectModal: false,
			selectedDevice: null
		})
	}
	handleDeviceAssignment(){
		this.getDevices()
	}

	handleDeviceSelection(patient){
		const {selectedDevice} = this.state
		const url = `/devices/${selectedDevice.id}`
		const data = {
			patientId: patient.id,
		}
		$.ajax({
			url: url,
			method: 'PUT',
			data: data
		})
		.done(() => {
			this.getDevices()
			this.closeDeviceSelectModal()
		})
		.fail((error) => {
			console.log(error)
			alert('Error assigning patient')
			this.closeDeviceSelectModal()
		})
	}

	renderDeviceItem(device, index){
		return (
			<DeviceItem
				key={device.id}
				device={device}
				assignPatient={this.assignPatient}
			/>
		)
	}


	renderPlan(data,index){
		var getSubscriptions = this.getSubscriptions.bind(this)
		return <PlanItem key={index}
			data={data}
			organization={this.props.organization}
			getSubscriptions={getSubscriptions}
			/>
	}

	renderSubscriptionItem(data, index){
		return <SubscriptionItem key={data.id} 
		index={index} 
		data={data} cards={this.state.cards} 
		organization={this.props.organization} 
		subscriptions={this.state.subscriptions}
		customer={this.state.customer}/>
	}

	


	render(){
		var renderSubscription = this.renderSubscriptionItem.bind(this)
		var subscriptionItems = this.state.subscriptions.map(renderSubscription)
		const deviceItems = this.state.devices.map(this.renderDeviceItem.bind(this))
		return (
			<div>
				<div className='text-center'> 
				Purchase the latest oximeters, accessories, and disposable probes at our online store: 
				<a href="https://www.sleepsat.com/online-store"> www.sleepsat.com/online-store</a>
				</div>
				<Payments user={this.props.user} organization={this.props.organization} subscriptions={this}/>
				<div className='card mb-4'>
					<div className='card-header'>
						<span className='mt-4 mb-4 h4'>Subscriptions</span>
					</div>
					<div className='card-body'>
						<legend className='mt-2'>Active Subscriptions</legend>
						<ul className='list-group'>
							{subscriptionItems}
						</ul>
					</div>
				</div>
				<div className='card mb-4'>
          <div className='card-header'>
            <span className='mt-4 mb-4 h4'>Devices</span>
          </div>
          <div className='card-body'>
            <div className='table-responsive'>
              <table className='table table-striped'>
                <thead>
                  <tr>
                    <th>Serial Number</th>
                    <th>Internal ID</th>
                    <th>Hub Serial Number</th>
                    <th>Assigned Patient</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{deviceItems}</tbody>
              </table>
            </div>
          </div>
          {this.state.showDeviceSelectModal && (
            <DeviceSelectModal
			  device={this.state.selectedDevice}
              closeModal={this.closeDeviceSelectModal}
              onPatientSelect={this.handleDeviceSelection}
              organization={this.props.organization}
              user={this.props.user}
			  onDeviceAssigned={this.handleDeviceAssignment}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Subscriptions
