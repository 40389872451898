import { Button, Input, Radio, Space, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import './styles.css';
import { GetDefinitionData, SaveDefinitionData, GetDeviceTypes, GetDeviceTypeId } from './api';

const keys = [
  'TransformationSampleRate',
  'TransformationZeroThreshold',
  'SnoreValueThreshold',
  'MinSingleSnoreDuration',
  'MaxSingleSnoreDuration',
  'MaxIntersnoreInterval',
  'MaxSameSnoreDelta',
];
const labels = [
  'Transformation SampleRate',
  'Transformation ZeroThreshold',
  'Snore Value Threshold',
  'Min Single Snore Duration',
  'Max Single SnoreDuration',
  'Max IntersnoreInterval',
  'Max Same Snore Delta',
];

function EventSettings() {
  const [field, setSelectedField] = useState('Hypopnea');
  const [definitionData, setDefinitionData] = useState(null);
  const [snoreData, setSnoreData] = useState(null);
  const [deviceTypes, setDeviceTypes] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [apneaSaving, setApneaSaving] = useState(false);
  const [snoreSaving, setSnoreSaving] = useState(false);

  useEffect(() => {
    (async () => {
      const data = await GetDeviceTypes();
      setDeviceTypes(data);
      //GetDeviceTypeId();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const s = field === 'RERA' ? 'RDIRERA' : field;
      const data = await GetDefinitionData(s);
      setDefinitionData(data);
    })();
  }, [field]);

  const setField = useCallback(
    (e, field) => {
      setDefinitionData({
        ...definitionData,
        [field]: parseFloat(e.target.value),
      });
    },
    [definitionData],
  );

  const saveData = useCallback((field, definitionData, setApneaSaving) => {
    const s = field === 'RERA' ? 'RDIRERA' : field;
    SaveDefinitionData(s, definitionData, null, setApneaSaving);
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedType) {
        const data = await GetDefinitionData('Snore', selectedType);
        setSnoreData(data);
      }
    })();
  }, [selectedType]);

  return (
    <div className="event-settings-form">
      <h4>{field}</h4>

      <div>
        {apneaSaving ? (
          <>
            <button className="btn btn-primary" type="button" disabled style={{ margin: '30px' }}>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving...
            </button>
          </>
        ) : (
          <div>
            <h5>Hypopnea/Apnea/RERA Priority</h5>
            <div>
              <Radio.Group value={field}>
                <Space direction="horizontal">
                  <Radio onChange={() => setSelectedField('Hypopnea')} defaultChecked={true} value={'Hypopnea'}>
                    Hypopnea
                  </Radio>
                  <Radio onChange={() => setSelectedField('Apnea')} value={'Apnea'}>
                    Apnea
                  </Radio>
                  <Radio onChange={() => setSelectedField('RERA')} value={'RERA'}>
                    RERA
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
            <br />
            <h5>{field} Detection</h5>
            <div>
              <div className="event-settings-field">
                <span>Start Offset</span>
                <Input
                  onChange={(e) => setField(e, 'TimeWindowStartOffset')}
                  value={definitionData?.TimeWindowStartOffset}
                  type="number"
                  step="any"
                />
                <span>sec</span>
              </div>
              <div className="event-settings-field">
                <span>End Offset</span>
                <Input
                  onChange={(e) => setField(e, 'TimeWindowEndOffset')}
                  value={definitionData?.TimeWindowEndOffset}
                  type="number"
                  step="any"
                />
                <span>sec</span>
              </div>
              <div className="event-settings-field">
                <span>Amplitude Ratio</span>
                <Input
                  onChange={(e) => setField(e, 'AmplitudeFallPercentage')}
                  value={definitionData?.AmplitudeFallPercentage}
                  type="number"
                  step="any"
                />
                <span>%</span>
              </div>
              {field !== 'Apnea' && (
                <div className="event-settings-field">
                  <span>Breath Rate Increase</span>
                  <Input
                    onChange={(e) => setField(e, 'BreathRateIncreasePercentage')}
                    value={definitionData?.BreathRateIncreasePercentage}
                    type="number"
                    step="any"
                  />
                  <span>%</span>
                </div>
              )}
              <div className="event-settings-field">
                <span>Minimum Duration</span>
                <Input
                  onChange={(e) => setField(e, 'MinCriteriaDuration')}
                  value={definitionData?.MinCriteriaDuration}
                  type="number"
                  step="any"
                />
                <span>sec</span>
              </div>
              {field === 'Hypopnea' && (
                <div className="event-settings-field">
                  <span>Default Start Offset</span>
                  <Input
                    onChange={(e) => setField(e, 'DefaultHypopneaStartOffset')}
                    value={definitionData?.DefaultHypopneaStartOffset}
                    step="any"
                    type="number"
                  />
                  <span>sec</span>
                </div>
              )}
            </div>
          </div>
        )}

        <div>
          <Button onClick={() => saveData(field, definitionData, setApneaSaving)} type="primary">
            Save
          </Button>
        </div>

        <br />
        <h4>Snore</h4>
        {snoreSaving ? (
          <>
            <button className="btn btn-primary" type="button" disabled style={{ margin: '30px' }}>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving...
            </button>
          </>
        ) : (
          <div>
            <h5>Snore Detection</h5>
            {deviceTypes && (
              <div className="event-settings-field">
                <span>Device Type Id</span>
                <Select
                  style={{ width: '180px', margin: '0 20px' }}
                  listHeight={500}
                  virtual={false}
                  value={selectedType}
                  onChange={(value, option) => {
                    // setSearchEvent(value);
                    // setSelectedSearchEvent(null);
                    setSelectedType(value);
                  }}
                  options={deviceTypes.map((t) => ({
                    label: t.Name,
                    value: t.DeviceTypeId,
                  }))}
                />
              </div>
            )}
            {snoreData &&
              keys.map((k, i) => (
                <div className="event-settings-field">
                  <span>{labels[i]}</span>
                  <Input
                    onChange={(e) => {
                      setSnoreData({
                        ...snoreData,
                        [k]: parseFloat(e.target.value),
                      });
                    }}
                    value={snoreData[k]}
                    type="number"
                    step="any"
                  />
                  {i > 2 && <span>sec</span>}
                </div>
              ))}
          </div>
        )}

        {selectedType && (
          <div>
            <Button onClick={() => SaveDefinitionData('Snore', snoreData, selectedType, setSnoreSaving)} type="primary">
              Save
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default EventSettings;
